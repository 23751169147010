import axios from "axios";
import store from "@/store";
import links from "@/links";
import VueJwtDecode from "vue-jwt-decode";
import authAPI from "@/api/auth";

export default router => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Accept"] = "application/json";

  axios.interceptors.request.use(
    async config => {
      // HACK: .. improvisation/trickery just for this case (logout user if not active for 2 hours)
      if (store.getters["auth/isContact"]) {
        const lastReqTimestamp = localStorage.getItem("last-req-timestamp")
          ? Number(localStorage.getItem("last-req-timestamp"))
          : undefined;

        const currentTime = new Date().valueOf();
        const unauthDateSafeLimit = currentTime - 2 * 60 * 60 * 1000; // 2h .. h m s ms -> ms

        if (lastReqTimestamp && lastReqTimestamp <= unauthDateSafeLimit) {
          store.dispatch("auth/deauthenticate");
          throw new axios.Cancel();
        }

        localStorage.setItem("last-req-timestamp", new Date().valueOf());
      }

      const accessToken = localStorage.getItem("access-token");
      if (
        accessToken &&
        config.url !== links.REFRESH_TOKEN() &&
        config.url !== links.REQUEST_TOKENS() &&
        config.url !== links.PING &&
        !config.url.includes(links.PAPPERS_API_LINK)
      ) {
        // set Authorization in headers
        config.headers.Authorization = `Bearer ${accessToken}`;

        const jwtDecoded = VueJwtDecode.decode(accessToken);
        const currentTime = new Date().valueOf() / 1000; // :ms -> s
        const expirationDateSafeLimit = jwtDecoded.exp - 60 * 60; // 60m .. m*s -> s
        if (currentTime >= expirationDateSafeLimit) {
          try {
            await store.dispatch("auth/refreshToken");
          } catch (err) {
            const errorResponse = err.response;
            if (errorResponse && errorResponse.status == 401) {
              throw new axios.Cancel(err);
            }
          }
          // set Authorization in headers equal to the NEW accessToken value (set by refreshToken action)
          config.headers.Authorization = `Bearer ${localStorage.getItem("access-token")}`;
        }
      }

      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    res => {
      return res;
    },
    async err => {
      if (
        err.response?.status &&
        err.response.status === 401 &&
        err.response?.data?.message &&
        err.response.data.message !== "Invalid current password"
      ) {
        if (store.getters["auth/isAuthenticated"]) {
          store.dispatch("auth/deauthenticate");
        }
      } else if (
        err.response?.status &&
        err.response.status === 404 &&
        err.response?.data?.message &&
        err.response.data.message === "Not Found"
      ) {
        return router.push({ name: "route.notFound" });
      } else if (err.config?.url && err.config.url !== links.PING) {
        try {
          await authAPI.ping();
        } catch (err) {
          // NOTE: Redirect if server down
          return router.push({ name: "route.serverDown" });
        }
      }

      return Promise.reject(err);
    }
  );
};
