import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  fetch(dossierId, phase) {
    let params = {};
    if (dossierId) {
      params["dossierId"] = dossierId;
    }

    if (phase) {
      params["phase"] = phase;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DEVIS_COMMUNICATIONS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  create(data) {
    return axios.post(links.DEVIS_COMMUNICATIONS_LINK(), data);
  },
  update(id, data, opts = {}) {
    const { isPhase7 } = opts;
    const params = { isPhase7 };
    const hasParamsSet = Object.keys(params).length > 0;

    return axios.put(
      links.DEVIS_COMMUNICATION_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      data
    );
  },
  delete(id) {
    return axios.delete(links.DEVIS_COMMUNICATION_LINK(id));
  },
  sendMessage(id, content, opts = {}) {
    const { isPhase7 } = opts;
    const params = { isPhase7 };
    const hasParamsSet = Object.keys(params).length > 0;

    return axios.put(
      links.DEVIS_COMMUNICATION_SEND_MESSAGE(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      { content }
    );
  },
  createClientCommunication(data) {
    return axios.post(links.DEVIS_COMMUNICATIONS_CLIENT_LINK(), data);
  },
};
