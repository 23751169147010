const devisCommunicationItemTypes = Object.freeze({
  DRAFT: { key: "draft", name: "Brouillon" },
  SENT: { key: "sent", name: "Expédié" },
  RESPONSE: { key: "response", name: "Réponse" },
  DEMAND_OFFER: { key: "demand_offer", name: "Demand Offer" },
  CONTACT_OFFER: { key: "contact_offer", name: "Contact Offer" },
  ACCEPT_OFFER: { key: "accept_offer", name: "Accept Offer" },
  DRAFT_CLIENT: { key: "draft_client", name: "Brouillon" },
  RESPONSE_CLIENT: { key: "response_client", name: "Client réponse" },
});

export { devisCommunicationItemTypes };
