import axios from "axios";
import links from "@/links";
import qs from "qs";
import { DOSSIERS_ITEMS_PER_PAGE } from "../constants/dossiers";

export default {
  getDossiersGroups({ search, status, responsible, specialDossiersFilter, insurer }) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (responsible) {
      params["responsible"] = responsible;
    }
    if (specialDossiersFilter) {
      params["specialDossiersFilter"] = specialDossiersFilter;
    }
    if (insurer) {
      params["insurer"] = insurer;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.DOSSIERS_GROUPS() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  getDossiers({
    search,
    status,
    responsible,
    specialDossiersFilter,
    insurer,
    cursorId,
    cursorOrderByValue,
    sortBy = "updatedAt",
    sortOrder = "desc",
  }) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (responsible) {
      params["responsible"] = responsible;
    }
    if (specialDossiersFilter) {
      params["specialDossiersFilter"] = specialDossiersFilter;
    }
    if (insurer) {
      params["insurer"] = insurer;
    }
    if (cursorId) {
      params["cursorId"] = cursorId;
    }
    if (cursorOrderByValue) {
      params["cursorOrderByValue"] = cursorOrderByValue;
    }
    params[`order[${sortBy}]`] = sortOrder;
    params["itemsPerPage"] = DOSSIERS_ITEMS_PER_PAGE;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.DOSSIERS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  countDossiers({ search, status, responsible, specialDossiersFilter, insurer }) {
    let params = {};
    if (search) {
      params["search"] = search;
    }
    if (status) {
      params["status"] = status;
    }
    if (responsible) {
      params["responsible"] = responsible;
    }
    if (specialDossiersFilter) {
      params["specialDossiersFilter"] = specialDossiersFilter;
    }
    if (insurer) {
      params["insurer"] = insurer;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.DOSSIERS_COUNT() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },

  getDossier(id) {
    return axios.get(links.DOSSIER_LINK(id));
  },
  getDossierByHash(hash) {
    return axios.get(links.DOSSIER_LINK_ANONYMOUS(hash));
  },
  getDossiersCountedByZipcode() {
    return axios.get(links.DOSSIERS_MAP_LINK());
  },
  updateDossierByHash(hash, data, tarifer, agencyCall, changeEmailForThisDossier) {
    let params = {};
    if (tarifer) {
      params["tarifer"] = 1;
    }
    if (agencyCall) {
      params["agencyCall"] = 1;
    }

    if (changeEmailForThisDossier) {
      params["changeEmailForThisDossier"] = changeEmailForThisDossier;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.put(
      links.DOSSIER_LINK_ANONYMOUS(hash) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      data
    );
  },
  createDossier(data, agencyCall) {
    let params = {};
    if (agencyCall) {
      params["agencyCall"] = 1;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.post(
      links.DOSSIERS_LINK() + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      data
    );
  },
  cancelDossier(id, data) {
    return axios.put(links.DOSSIER_CANCEL(id), data);
  },
  rejectDossier(id, data) {
    return axios.put(links.DOSSIER_REJECT(id), data);
  },
  deleteDossier(id, data) {
    return axios.put(links.DOSSIER_DELETE(id), data);
  },
  hardDeleteDossier(id) {
    return axios.delete(links.DOSSIER_LINK(id));
  },
  restoreDossier(id) {
    return axios.put(links.DOSSIER_RESTORE(id));
  },
  offer(hash) {
    return axios.get(links.OFFER(hash));
  },
  put(id, item, tarifer, changeEmailForThisDossier) {
    let params = {};
    if (tarifer) {
      params["tarifer"] = 1;
    }

    if (changeEmailForThisDossier) {
      params["changeEmailForThisDossier"] = changeEmailForThisDossier;
    }

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.put(
      links.DOSSIER_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      item
    );
  },
  basicUpdate(id, dossierData) {
    return axios.put(links.DOSSIER_BASIC_LINK(id), dossierData);
  },
  updateNote(id, dossierNoteText) {
    return axios.put(links.DOSSIER_NOTE_LINK(id), { dossierNoteText });
  },
  updateSuspendCommunication(id, suspendCommunication) {
    return axios.put(links.DOSSIER_SUSPEND_COMMUNICATION_LINK(id), { suspendCommunication });
  },
  contactOffer(hash, item) {
    return axios.post(links.CONTACTOFFER(hash), item);
  },
  contact(hash, item) {
    return axios.post(links.DOSSIER_CONTACT(hash), item);
  },
  offerRequest(hash) {
    return axios.get(links.OFFERREQUEST(hash));
  },
  offerRequestAgency(id) {
    return axios.get(links.OFFERREQUESTAGENCY(id));
  },
  updateAgencyCompany(id, item) {
    return axios.put(links.DOSSIERS_AGENCY_COMPANY_LINK(id), item);
  },
  updateResponsible(id, item) {
    return axios.put(links.DOSSIERS_RESPONSIBLE_LINK(id), item);
  },
  updatePartner(id, item) {
    return axios.put(links.DOSSIERS_PARTNER_LINK(id), item);
  },

  checkInvalidDocuments(dossierId) {
    return axios.get(links.DOSSIER_CHECK_INVALID_DOCUMENTS(dossierId));
  },
  countInvalidDocuments(dossierId, opts = {}) {
    const { dossierDetailsModels, isPhase7 } = opts;

    let params = {
      dossierDetailsModels,
      isPhase7,
    };

    const hasParamsSet = Object.keys(params).length > 0;

    return axios.get(
      links.DOSSIER_COUNT_INVALID_DOCUMENTS(dossierId) +
        (hasParamsSet ? "?" + qs.stringify(params) : "")
    );
  },
  getInvalidDocumentsSynthesis(dossierId) {
    return axios.get(links.DOSSIER_INVALID_DOCUMENTS_SYNTHESIS(dossierId));
  },
  sendInvalidDocumentsSynthesisReminder(dossierId, extraComment) {
    return axios.post(links.DOSSIER_SEND_INVALID_DOCUMENTS_SYNTHESIS_REMINDER(dossierId), {
      extraComment: extraComment,
    });
  },
  getClientInvalidDocumentsSynthesis(dossierId) {
    return axios.get(links.DOSSIER_CLIENT_INVALID_DOCUMENTS_SYNTHESIS(dossierId));
  },

  checkContactHasOnlyOneDossier() {
    return axios.get(links.CHECK_CONTACT_HAS_ONLY_ONE_DOSSIER());
  },
  generateDocument(dossierId, data) {
    return axios.post(links.GENERATE_DOCUMENT(dossierId), data);
  },
  sendInviteDossier(id) {
    return axios.post(links.DOSSIER_SEND_INVITE(id));
  },
  updateAdditionalFields(id, item) {
    return axios.put(links.DOSSIERS_ADDITIONAL_FIELDS_LINK(id), item);
  },
  statistics(opts) {
    let { dates, insurer, averageTimeLast } = opts;
    let params = {
      dates,
      insurer,
      averageTimeLast,
    };
    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(links.STATISTICS() + (hasParamsSet ? "?" + qs.stringify(params) : ""));
  },
  triggerValidation(dossierId, phaseKey) {
    return axios.put(links.DOSSIERS_TRIGGER_PHASE_VALIDATION(dossierId), { phaseKey });
  },
  removeContractNumber(id) {
    return axios.post(links.DOSSIERS_REMOVE_CONTRACT_NUMBER_LINK(id));
  },
  deleteResponsiblePartner(id, opts) {
    let { responsible, partner, secondResponsible } = opts;

    return axios.put(links.DOSSIER_DELETE_RESPONSIBLE_PARTNER_LINK(id), {
      responsible,
      partner,
      secondResponsible,
    });
  },
  getStatisticsForDossiers(opts, signal) {
    let { dates, insurers, typeComparison, employee, origin } = opts;
    let params = {
      dates,
      insurers,
      typeComparison,
      employee,
      origin,
    };
    const hasParamsSet = Object.keys(params).length > 0;
    return axios.get(
      links.DOSSIERS_STATISTICS() + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      {
        signal,
      }
    );
  },
  generateUrlForInvitationClient(id) {
    return axios.get(links.DOSSIER_URL_SEND_INVITE(id));
  },
  updateInvalidDocumentsComment(id, invalidDocumentsComment) {
    return axios.put(links.DOSSIER_INVALID_DOCUMENTS_COMMENT_LINK(id), { invalidDocumentsComment });
  },
  updateContractNumber(id, item) {
    return axios.put(links.DOSSIERS_UPDATE_CONTRACT_NUMBER_LINK(id), item);
  },
  updateLockedDossier(id, item) {
    return axios.put(links.DOSSIERS_UPDATE_LOCKED_DOSSIER_LINK(id), item);
  },
  checkClientIsActivated(id) {
    return axios.get(links.DOSSIER_CHECK_CLIENT_IS_ACTIVATED(id));
  },
  createBeneficiaries(id, item) {
    return axios.post(links.DOSSIERS_BENEFICIARIES_LINK(id), item);
  },
  updateBeneficiaries(id, item, beneficiaryIndex, isValidated = false) {
    let params = {};
    if (isValidated) {
      params["isValidated"] = 1;
    }
    params["beneficiaryIndex"] = beneficiaryIndex;

    const hasParamsSet = Object.keys(params).length > 0;
    return axios.put(
      links.DOSSIERS_BENEFICIARIES_LINK(id) + (hasParamsSet ? "?" + qs.stringify(params) : ""),
      item
    );
  },
};
